import { useState, useContext } from "react";
import {
  FaFacebookF,
  // FaPhoneAlt,
  FaEnvelope,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";
import useGlobalContext from "../../../hooks/useGlobalContext";

// import useAnalyticsEventTracker from '../../../services/useAnalyticsEventTracker';
import { businessLogic } from "../../../lib/constants";
import { useTranslation } from "react-i18next";

// import { AppContext } from "../../../context/AllContext";
// const { handleTrans } = AppContext();

import { AppContext } from '../../../context/AllContext';


// const lngs = [
//   { code: "en", native: "English" },
//   { code: "es", native: "Spanish" },
// ];



const HomeOneHeader = () => {
  const { handleTrans } = useContext(AppContext);
  const { t, i18n } = useTranslation();


  
  // const handleTrans = (code) => {
  //   i18n.changeLanguage(code);
  // };

  // const gaEventTracker = useAnalyticsEventTracker('Contact us');

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { stickyMenu } = useGlobalContext();
  return (
    <>
      <header>
        <div className="header__area p-relative header__transparent">
          <div className="d-none d-md-block">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-6 col-lg-5 col-md-4">



                {/* <div style={{padding: '50px'}}>
                  {lngs.map((lng, i) => {
                    const { code, native } = lng;
                    return <button onClick={() => handleTrans(code)}>{native}</button>;
                    
                  })}
                </div> */}



                  <div className="header__social">
                    <ul>


                    <li>
                        <a href={`${businessLogic.linkedin}`} target="blank">
                          <i>
                            <FaLinkedinIn />
                          </i>
                        </a>
                      </li>


                      <li>
                        <a href={`${businessLogic.instagram}`} target="blank">
                          <i>
                            <FaInstagram />{" "}
                          </i>
                        </a>{" "}
                      </li>


                      <li>
                        <a href={`${businessLogic.facebook}`} target="blank">
                          <i>
                            <FaFacebookF />
                          </i>{" "}
                        </a>
                      </li>





                      {/* {lngs.map((lng, i) => {
                    const { code, native } = lng;
                    return <button onClick={() => handleTrans(code)}>{native}</button>;
                  })} */}





                    </ul>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-7 col-md-8">
                  <div className="header__info f-right">
                    <ul>
                      <li>
                        <a href={`${businessLogic.phoneNumber}`}>
                          <i>
                            {" "}
                            {/* <FaPhoneAlt />{" "} */}
                          </i>
                          {businessLogic.phoneNumber}
                        </a>
                      </li>
                      <li>
                        <a href={`mailto:${businessLogic.emailBase}`} >
                        {/* <a href={`mailto:${businessLogic.emailBase}`} onClick={()=>gaEventTracker('email')}> */}
                          <i>
                            {" "}
                            <FaEnvelope />{" "}
                          </i>
                          {businessLogic.emailBase}
                        </a>
                      </li>


                      <li>
                        <div className="language__newsletter">
                          {/* <input type="hidden" name="language" id="languageField" value="" /> */}
                            <img onClick={() => handleTrans("es")} src="../assets/img/icon/flags/spain_rounded.png" alt="Spanish" id="spanishFlag" className="flag" style={{cursor:'pointer'}} />
                              <label>&nbsp;&nbsp;</label>
                            <img onClick={() => handleTrans("en")}  src="../assets/img/icon/flags/unitedkingdom_rounded.png" alt="English" id="englishFlag" className="flag" style={{cursor:'pointer'}}/>
                        </div>
                      </li>








                      {/* <li>
                        <a href="#" className="search-toggle">
                          <i>
                            {" "}
                            <FaSearch />{" "}
                          </i>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
                    </div>
                            
            </div>
          </div>
          <div
            id="header__sticky"
            className={stickyMenu ? "sticky header__bottom" : "header__bottom"}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                  <div className="logo">
                    <NavLink to="/">
                      <img
                        src={`../assets/img/logo/${businessLogic.logoNegative}`}
                        alt="logo"
                        style={{ height: 35 }}
                      />
                    </NavLink>
                  </div>
                  <div className="logo-gradient">
                    <NavLink to="/">
                      <img
                        src={`../assets/img/logo/${businessLogic.logoBlack}`}
                        alt="logo"
                        style={{ height: 35 }}
                      />
                    </NavLink>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-9 col-md-6 col-sm-6 col-6">
                  <div className="header__bottom-right d-flex justify-content-end align-items-center">
                    <div className="main-menu menu_wrapper_one">
                      <nav id="mobile-menu">
                        <ul>


                          <li>
                            <NavLink to="/home">{t("navbar_home")}</NavLink>
                            {/* <ul className="submenu">
                                <li><NavLink to="/home">Home Style 1</NavLink></li>
                                <li>
                                  <NavLink to="/homeTwo">Home Style 2</NavLink>
                                  <ul className="submenu">
                                      <li><NavLink to="/home">Home Style 1</NavLink></li>
                                      <li><NavLink to="/homeTwo">Home Style 2</NavLink></li>
                                      <li><NavLink to="/homeThree">Home Style 3</NavLink></li>
                                  </ul>
                                </li>
                                <li>
                                  <NavLink to="/homeThree">Home Style 3</NavLink>
                                  <ul className="submenu">
                                      <li><NavLink to="/headerStyleFour">Header Syle 4</NavLink></li>
                                      <li><NavLink to="/headerStyleFive">Header Syle 5</NavLink></li>
                                      <li><NavLink to="/headerStyleSix">Header Syle 6</NavLink></li>
                                      <li><NavLink to="/headerStyleSeven">Header Syle 7</NavLink></li>
                                  </ul>
                                </li>
                            </ul> */}
                          </li>



                          <li>
                            <NavLink to="/about">{t("navbar_about_us")}</NavLink>
                                        {/* <h1>{t("welcome")}</h1> */}

                          </li>




                          <li>
                            <NavLink to="/services">{t("navbar_services")}</NavLink>
                            {/* <ul className="submenu">
                              <li>
                                <NavLink to="/services">Services</NavLink>
                              </li>
                              <li>
                                <NavLink to="/servicesDetails">
                                  Services Details
                                </NavLink>
                              </li>
                            </ul> */}
                          </li>





                          {/* PORTFOLIO DETAILS IS PERFECT TO USE FOR FAQs */}
                          {/* PORTFOLIO DETAILS IS PERFECT TO USE FOR FAQs */}
                          {/* PORTFOLIO DETAILS IS PERFECT TO USE FOR FAQs */}
                          {/* PORTFOLIO DETAILS IS PERFECT TO USE FOR FAQs */}
                          {/* PORTFOLIO DETAILS IS PERFECT TO USE FOR FAQs */}
                          {/* PORTFOLIO DETAILS IS PERFECT TO USE FOR FAQs */}

                          <li>
                            <NavLink to="/portfolio">{t("navbar_portfolio")}</NavLink>
                            {/* <ul className="submenu">
                              <li>
                                <NavLink to="/portfolio">portfolio</NavLink>
                              </li>
                              <li>
                                <NavLink to="/portfolioDetails">
                                  portfolio Details
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to="/team">team</NavLink>
                              </li>
                              <li>
                                <NavLink to="/teamDetails">
                                  team Details
                                </NavLink>
                              </li>
                            </ul> */}
                          </li>









                          {/* <li>
                            <NavLink to="/resources">{t("navbar_resources")}</NavLink>
                          </li> */}





                          <li>
                            <NavLink to="/blogs">{t("navbar_blog")}</NavLink>
                            {/* <ul className="submenu">
                              <li>
                                <NavLink to="/blogs">Blog</NavLink>
                              </li>
                              <li>
                                <NavLink to="/blogDetails">
                                  Blog Details
                                </NavLink>
                              </li>
                            </ul> */}
                          </li>



                          {/* <li>
                            <NavLink to="/contact">Contact Us</NavLink>
                          </li> */}







                        </ul>
                      </nav>
                    </div>
                    <div className="header__btn d-none d-sm-block d-lg-none d-xl-block ml-50">
                      {/* <Link to="/contact" className="z-btn z-btn-white" onClick={()=>gaEventTracker('call')}> */}
                      
                      <Link to="/contact" className="z-btn z-btn-white" >
                      {t("contact_us")}
                      </Link>
                    </div>
                    <div
                      onClick={handleShow}
                      className="sidebar__menu d-lg-none"
                    >
                      <div className="sidebar-toggle-btn" id="sidebar-toggle">
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          {/* <div className="header__search-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <form action="#">
                    <input type="text" placeholder="Your Keywords" />
                    <button type="button">
                      <i>
                        {" "}
                        <p></p>
                        <FaSearch />{" "}
                      </i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div> */}


          <div className="body-overlay-2"></div>
        </div>



      </header>

      <Sidebar show={show} handleClose={handleClose} />
    </>
  );
};

export default HomeOneHeader;
