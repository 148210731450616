import React, { useEffect, useState } from 'react';
import { constants } from '../../lib/constants';
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";






export default function EbookForm(){
    const { t } = useTranslation();

    let createUserNewsletter = constants.createUserNewsletter;

    const [email, setEmail] = useState('');
    const [language, setLanguage] = useState('');
    const [languageError, setLanguageError] = useState('');
    const [politics, setPolitics] = useState(false);

 useEffect(() => {
        const handleFlagClick = (languageCode) => {
            clearFlagSelection();
            setLanguage(languageCode);
            setLanguageError('');
            
            const selectedFlag = document.getElementById(`${languageCode}Flag`);
            if (selectedFlag) {
                selectedFlag.classList.add('selected-flag');
            }
        };

        const spanishFlag = document.getElementById('espFlag');
        const englishFlag = document.getElementById('engFlag');

        spanishFlag.addEventListener('click', () => handleFlagClick('esp'));
        englishFlag.addEventListener('click', () => handleFlagClick('eng'));

        return () => {
            spanishFlag.removeEventListener('click', () => handleFlagClick('esp'));
            englishFlag.removeEventListener('click', () => handleFlagClick('eng'));
        };
    }, []);

    const clearFlagSelection = () => {
        document.querySelectorAll('.flag').forEach(flag => {
            flag.classList.remove('selected-flag');
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLanguageError('');

        if (!politics) {
            toast.error(t("generic_newsletter_toast_error_politics"));
            return;
        }

        if (!language) {
            setLanguageError(t("generic_newsletter_toast_error_select_language"));
            return;
        }

        const formData = {
            email,
            language,
            politics,
        };

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formData),
        };

        fetch(createUserNewsletter, requestOptions)
            .then((response) => {
                if (response.status === 201) {
                    toast.success(t("generic_toast_success_send_message"));
                } else {
                    toast.error(t("generic_toast_error_notsend_message"));
                }
            })
            .catch((error) => {
                toast.error(error);
            });
    };

            


                return(
                    <div className="col-xl-10 col-lg-3 col-md-5 col-sm-6">
                        <form id="newsletterForm" onSubmit={ handleSubmit } >

                            <div className="footer__widget mb-45">
                                

                                <div className="footer__widget-content">
                                    <div className="footer__subscribe">
                                    <p className="mb-20">{t("resources_download_ebook_section_download_history")}</p>                      
                                    </div>
                                </div>

                                <div className="language__newsletter">
                                <input type="hidden" name="language" id="languageField" value="" />

                                        {/* ../assets/img/blog/author/InvokeApp.svg */}
                                        <img  src="../assets/img/icon/flags/spain_rounded.png" alt="Spanish" id="espFlag" className="flag" style={{cursor:'pointer'}} />
                                        <label>&nbsp;&nbsp;</label>
                                        <img  src="../assets/img/icon/flags/unitedkingdom_rounded.png" alt="English" id="engFlag" className="flag" style={{cursor:'pointer'}}/>
        
                                <label>&nbsp;&nbsp;{t("resources_download_ebook_section_language_ebook")}</label>
                                {languageError && <div style={{ color: 'red', marginTop: '10px' }}>{languageError}</div>}

                                </div>

                                <br/>
                                <div class="checkbox-container">
                                    <input type="checkbox" id="acceptPolicy" name="acceptPolicy" checked={politics} onChange={(e) => setPolitics(e.target.checked)} required />
                                    <label >&nbsp;&nbsp;&nbsp;{t("blog_newsletter_policy_one")}<a href="/ebook-policies">{t("blog_newsletter_policy_two")}</a></label>
                                    {/* for="acceptPolicy" */}
                                </div>
                                <br/>

                                <div className="footer__widget-content">
                                    <div className="footer__subscribe">
                                        <div className="footer__subscribe-form p-relative">
                                            <input type="email" name="email" placeholder={t("blog_newsletter_email_adress_input")} value={email} onChange={(e) => setEmail(e.target.value)} required />
                                            <button type="submit" id="subscribeButton">{t("resources_download_ebook_section_boton_download")}</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                        <ToastContainer />

                    </div>
    )

}
