import React from 'react';
import HomeThreeSecondSingleService from '../../../components/HomeThreeSecondSingleService/HomeThreeSecondSingleService';
import { SiKnowledgebase } from 'react-icons/si';
import { BsTools, BsHeart, BsBag } from 'react-icons/bs';

import { FaMobileAlt, FaRegComments } from 'react-icons/fa';
import { useTranslation } from "react-i18next";


const HomeThreeSecondServices = () => {
   const { t } = useTranslation();


   return (
      <>
         <section className="services__area-3 pt-115 pb-160">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6">
                     <div className="section-title section__title-3 mb-70">
                        <h2>{t("services_three_second_services_title")}</h2>
                        <p>{t("services_three_second_services_subtitle")}</p>
                     </div>

                  </div>
               </div>
               <div className="row">
                  <div className="col-xl-12">
                     <div className="services__nav wow fadeInUp" data-wow-delay=".4s">
                        <ul className="nav nav-pills " id="services-tab" role="tablist">



                           <li className="nav-item mb-45">
                              <a className="nav-link active" id="share-tab" data-bs-toggle="pill" href="#share" role="tab" aria-controls="share" aria-selected="true">
                                 <i> <FaMobileAlt/> </i>{t("services_three_second_services_section_one_title_superior")}
                              </a>
                           </li>



                           <li className="nav-item mb-30">
                              <a className="nav-link" id="customer-tab" data-bs-toggle="pill" href="#customer" role="tab" aria-controls="customer" aria-selected="true">
                                 <i ><BsTools/></i>{t("services_three_second_services_section_two_title_superior")}
                              </a>
                           </li>



                           <li className="nav-item mb-30">
                              <a className="nav-link" id="social-tab" data-bs-toggle="pill" href="#social" role="tab" aria-controls="social" aria-selected="true">
                                 <i ><FaRegComments/></i>{t("services_three_second_services_section_three_title_superior")}
                              </a>
                           </li>



                           <li className="nav-item mb-30">
                              <a className="nav-link" id="financial-tab" data-bs-toggle="pill" href="#financial" role="tab" aria-controls="financial" aria-selected="true">
                                 <i ><BsHeart/></i>{t("services_three_second_services_section_four_title_superior")}
                              </a>
                           </li>



                        </ul>
                     </div>
                  </div>
                  <div className="col-xl-12">
                     <div className="services__tab grey-bg-18">
                        <div className="tab-content" id="services-content">
                        
                           <div className="tab-pane fade show active" id="share" role="tabpanel" aria-labelledby="share-tab">
                              <HomeThreeSecondSingleService 
                              title={t("services_three_second_services_section_one_title")} 
                              icon={9} image={5} 
                              powerword={t("services_three_second_services_section_one_power_phrase")}
                              description={t("services_three_second_services_section_one_subtitle")} />
                           </div>

                           <div className="tab-pane fade" id="customer" role="tabpanel" aria-labelledby="customer-tab">
                              <HomeThreeSecondSingleService 
                              title={t("services_three_second_services_section_two_title")} 
                              icon={2} image={3} 
                              powerword={t("services_three_second_services_section_two_power_phrase")}
                              description={t("services_three_second_services_section_two_subtitle")} />
                           </div>

                           <div className="tab-pane fade" id="social" role="tabpanel" aria-labelledby="social-tab">
                              <HomeThreeSecondSingleService 
                              title={t("services_three_second_services_section_three_title")} 
                              icon={3} image={1} 
                              powerword={t("services_three_second_services_section_three_power_phrase")}
                              description={t("services_three_second_services_section_three_subtitle")} />
                           </div>

                           <div className="tab-pane fade" id="financial" role="tabpanel" aria-labelledby="financial-tab">
                              <HomeThreeSecondSingleService 
                              title={t("services_three_second_services_section_four_title")} 
                              icon={5} image={4} 
                              powerword={t("services_three_second_services_section_four_power_phrase")}
                              description={t("services_three_second_services_section_four_subtitle")} />
                           </div>

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeThreeSecondServices;