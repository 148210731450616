import React from 'react';
import { BiLock } from 'react-icons/bi';
import { CgArrowLongRight } from 'react-icons/cg';
import { FaLightbulb, FaRibbon } from 'react-icons/fa';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import HomeTwoSingleFeature from '../../../components/HomeTwoSingleFeature/HomeTwoSingleFeature';
import { useTranslation } from "react-i18next";



const HomeTwoFeatures = () => {
  const { t } = useTranslation();

   return (
      <>
         <section className="features__area pt-115 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-5 col-lg-6">
                     <div className="features__content-left">
                        <div className="section__title section__title-h2 mb-25">
                           <span>{t("home_two_features_title_superior_mini")}</span>
                           <h2>{t("home_two_features_title_one")}<br/>{t("home_two_features_title_two")}</h2>
                        </div>
                        <p>{t("home_two_features_subtitle")}</p>
                        <Link to="/about" className="z-btn">{t("home_two_features_button_text")}<i><CgArrowLongRight/></i></Link>
                     </div>
                  </div>
                  <div className="col-xl-6 offset-xl-1 col-lg-6">
                     <div className="features__content-right">
                        <div className="row">
                           <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">

                              <HomeTwoSingleFeature url={"/time-maximization"} icon={<FaLightbulb />} title={t("home_two_features_card_title_one")} description={t("home_two_features_card_description_one")}/>
                              <HomeTwoSingleFeature url={"/customer-retention"} icon={<IoDocumentTextOutline />} title={t("home_two_features_card_title_three")} description={t("home_two_features_card_description_three")}/>

                           </div>

                           <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">

                              <HomeTwoSingleFeature url={"/operational-efficiency"} icon={<FaRibbon />} title={t("home_two_features_card_title_two")} description={t("home_two_features_card_description_two")}/>
                              <HomeTwoSingleFeature url={"/lead-conversion"} icon={<BiLock />} title={t("home_two_features_card_title_four")} description={t("home_two_features_card_description_four")}/>

                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

      </>
   );
};

export default HomeTwoFeatures;