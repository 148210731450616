import React from 'react';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import { useTranslation } from "react-i18next";

import GridLeadConversion from './GridLeadConversion';





const LeadConversion = () => {
  const { t } = useTranslation();


   return (
      <>
         <PageHelmet pageTitle="Lead Conversion" />

         <HomeOneHeader/>
         <CommonPageHeader title="Lead Conversion" subtitle="Lead Conversion" />

         {/* <HomePricing/> */}
         {/* <TermPolicies/> */}
         


         <GridLeadConversion/>



         {/* <HomeServices />
         <HomeCapabilities/>
         <HomeThreeSecondServices /> */}
         <CommonCtaArea/>
         <Footer/>
      </>
   );
};

export default LeadConversion;