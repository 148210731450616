import React from 'react';
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import EbookForm from '../../Resources/EbookForm';



const DownloadEbookResources = () => {
   const { t } = useTranslation();


   // slick setting
   const settings = {
      autoplay: true,
      autoplaySpeed: 10000,
      dots: true,
      fade: false,
      arrows: false,

   }
   const testimonialData = [
      {
         id:1,
         name: t("resources_download_ebook_section_title_story_one_author"),
         title: t("resources_download_ebook_section_title_story_one_position"),
         description: t("resources_download_ebook_section_title_story_one")
      },
      {
         id:2,
         name: t("resources_download_ebook_section_title_story_two_author"),
         title: t("resources_download_ebook_section_title_story_two_position"),
         description: t("resources_download_ebook_section_title_story_two")
      },
   ]

   return (
      <>
         {/* <section className="testimonial__area gradient-bg"> */}
         <section className="testimonial__area">
            <div className="container">
               <div className="row">
                  <div className="col-xl-5 col-lg-6">
                     <div className="testimonial__content pt-165 pb-175">
                        <div className="section__title section__title-3 mb-30">
                           <span className="">{t("resources_download_ebook_section_title_mini")}</span>
                           <h2 className="">{t("resources_download_ebook_section_title")}</h2>
                        </div>

                        <Slider className='testimonial__slider pb-70' {...settings}>

                           {
                              testimonialData.map((testimonial,index) => {
                                 return <div key={index} className="testimonial__item">
                                    <p className="">{testimonial.description}</p>
                                    <div className="testimonial__content d-flex align-items-center">
                                       
                                       <div className="quote mr-20">
                                          <img src="assets/img/icon/blog/quote-1.png" alt="quote" />
                                       </div>
                                       <div className="testimonial__info">
                                          <h4 className="">{testimonial.name}</h4>
                                          <span className="">{testimonial.title}</span>
                                       </div>
                                    </div>

                                 </div>
                              })
                           }

                        </Slider>

                     </div>
                  </div>




                 <div className="col-xl-7 col-lg-6 pb-50">
                     <div className="testimonial text-end pt-100" style={{ position: 'relative' }}>
                        <img src="assets/img/testimonial/testimonial-2.svg" alt="downloadable resources" style={{ width: '100%', height: 'auto', zIndex: '0' }}/>
                        <div style={{
                           position: 'absolute',
                           top: '55%',
                           left: '50%',
                           transform: 'translate(-40%, -50%)',
                           zIndex: '1',
                        }}>
                           <EbookForm />
                        </div>
                     </div>
                  </div>


                  
               </div>
            </div>
         </section>
      </>
   );
};

export default DownloadEbookResources;