import React from 'react';
import {  FaRegComments } from 'react-icons/fa';
import { CgArrowLongRight } from 'react-icons/cg';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";


const BlogSingleItem = ({imagee,user_image,name,title,video_icon, article, date}) => {
   const { t } = useTranslation();

   const MAX_LENGTH = 250;

   return (
      <>
         <div className="blog__item-2 mb-50 fix"  key={article.UUID}>
            <div className={`blog__thumb-2 w-img fix ${video_icon && 'p-relative'}`}>
               <Link to={`/blogDetails/${article.link}`}>
                  {/* <img src={`assets/img/blog/blog-${imagee}.jpg`} alt="" /> */}
                  <img src={article.image} alt="" />
               </Link>
               {/* {video_icon && video_icon} */}
            </div>
            <div className="blog__content-2">
               <div className="blog__meta-2 mb-15 d-sm-flex align-items-center">
                  <div className="blog__user pr-20 mr-20">
                     <Link to={`/blogDetails/${article.link}`}>
                        <img src={`assets/img/blog/author/InvokeApp.svg`} alt="" height={35} width={35}/>
                        <h6>{article.author}</h6>
                     </Link>
                  </div>
                  <div className="blog__date">

                     {/* <span> {{date}.split("T")[0]} </span> */}
                     {/* <span> {{date}.split("T")[0]} </span> */}

                     <span> { date } </span>
                  </div>
               </div>
               <h3>
                  <Link to={`/blogDetails/${article.link}`}>{title}</Link>
               </h3>

               {article.html_content.length > MAX_LENGTH ?
                  (
                     <div>
                     <div dangerouslySetInnerHTML={{__html: `${article.html_content.substring(0, MAX_LENGTH)}    ...     ` }}></div>
                        
                        {/* {`${article.content.substring(0, MAX_LENGTH)}    ...     `} */}
                     </div>
                  ) :
                  // <p>{article.content}</p>
                  <p>                        
                     <div dangerouslySetInnerHTML={{__html: `${article.html_content}` }}></div>
                  </p>
                  }

               <div className="blog__btn d-sm-flex justify-content-between pt-3">
                  <div className="blog__btn">
                     <Link to={`/blogDetails/${article.link}`} className="link-btn-2">
                     {t("blog_read_more_card")}
                        <i > <CgArrowLongRight/> </i>
                        <i > <CgArrowLongRight /> </i>
                     </Link>
                  </div>




                  {/* <div className="blog__comment">
                     {article.comments.length > 0 ? 
                     <Link to={`/blogDetails/${article.link}`}> 
                        <i><FaRegComments/></i>    {t("blog_comments_card")} ( { article.comments != null ? (article.comments.length) : 0 } )
                     </Link>
                     : <div>
                        <i><FaRegComments/></i>    {t("blog_comments_card")} ( { article.comments != null ? (article.comments.length) : 0 } ) 
                        </div>
                     }     
                  </div> */}



               </div>
            </div>
         </div>
      </>
   );
};

export default BlogSingleItem;