import React, { useState } from "react";

import { AiOutlinePlus } from "react-icons/ai";
import { CgArrowLongRight } from "react-icons/cg";

import Lightbox from 'react-image-lightbox';

export function PortfolioStaticAll({ data }) {
    const { image1, title, service } = data;



    // const [photoIndex, setPhotoIndex] = useState(data.id);
    const [open, setOpen] = useState(false);
    // const lightboxImages = data.map(img => img.img);
    // const lightboxImages = data.img;
    // const images = lightboxImages


    const handleOpen = () => {
        console.log("handle open")
        setOpen(!open);
    }


    // const dataArray = data.map((item) => {
      return (
        <>
         
         {open && (
            <Lightbox
            //    mainSrc={images[photoIndex]}
               mainSrc={image1}
            //    nextSrc={images[(photoIndex + 1) % images.length]}
            //    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
               onCloseRequest={() => setOpen(false)}
            //    onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
            //    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
            />
         )}

            {/* <SinglePortfolio key={item.id} portfolio={item} filterPortfolio={item} index={item.id} /> */}
  
            {/* <div className={img === "assets/img/portfolio/port-1.jpg" ? "col-xl-8 col-lg-8 col-md-6 col-sm-6" : "col-xl-4 col-lg-4 col-md-6 col-sm-6"} key={data.id}> */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6" key={data.id}>

              <div className="portfolio__item p-relative mb-30">
                <div className="portfolio__thumb w-img fix">
                  <img src={image1} alt="" />
                  <div className="portfolio__plus p-absolute transition-3">
                     {/* <button onClick={() => setPhotoIndex(data.id)}> */}
                     <button onClick={() => handleOpen()}>
                     {/* <button > */}
                      <i>
                        {" "}
                        <AiOutlinePlus />{" "}
                      </i>
                      <i>
                        {" "}
                        <AiOutlinePlus />{" "}
                      </i>
                    </button>
                  </div>
                </div>
                <div className="portfolio__content">
                  <h4>{title}</h4>
                  {/* <h4><Link to="/portfolioDetails">{title}</Link></h4> */}
                  <p>{service}</p>
                  <div className="portfolio__more p-absolute transition-3">
                    {/* <Link to="/portfolioDetails" className="link-btn-2"> */}
                
                    Show Project
                    <i>
                      {" "}
                      <CgArrowLongRight />{" "}
                    </i>
                    <i>
                      {" "}
                      <CgArrowLongRight />{" "}
                    </i>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
        </>
      );
    // });
  
    // return <div>{dataArray}</div>;
  }
  



export default PortfolioStaticAll;
























// export function PortfolioStaticAll({ data }) {
//   const dataArray = data.map((item) => {
//     return (
//       <>
//           {/* <SinglePortfolio key={item.id} portfolio={item} filterPortfolio={item} index={item.id} /> */}

//           {/* <div className={item.img === "assets/img/portfolio/port-1.jpg" ? "col-xl-8 col-lg-8 col-md-6 col-sm-6" : "col-xl-4 col-lg-4 col-md-6 col-sm-6"} key={item.id}> */}
//           <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6" key={item.id}>
//             <div className="portfolio__item p-relative mb-30">
//               <div className="portfolio__thumb w-img fix">
//                 <img src={item.img} alt="" />
//                 <div className="portfolio__plus p-absolute transition-3">
//                   <button onClick={() => console.log("CLICK")}>
//                     <i>
//                       {" "}
//                       <AiOutlinePlus />{" "}
//                     </i>
//                     <i>
//                       {" "}
//                       <AiOutlinePlus />{" "}
//                     </i>
//                   </button>
//                 </div>
//               </div>
//               <div className="portfolio__content">
//                 {/* <h4><Link to="/portfolioDetails">{title}</Link></h4> */}
//                 <p>{item.subtitle}</p>
//                 <div className="portfolio__more p-absolute transition-3">
//                   {/* <Link to="/portfolioDetails" className="link-btn-2"> */}
//                   Show Project
//                   <i>
//                     {" "}
//                     <CgArrowLongRight />{" "}
//                   </i>
//                   <i>
//                     {" "}
//                     <CgArrowLongRight />{" "}
//                   </i>
//                   {/* </Link> */}
//                 </div>
//               </div>
//             </div>
//           </div>
//       </>
//     );
//   });

//   return <div>{dataArray}</div>;
// }


