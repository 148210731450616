// import React from 'react';

// // const SingleBrandItem = ({img_1,img_2}) => {
// const SingleBrandItem = ({ data }) => {
//    console.log(data)
//    console.log(data[1].company)


//    const dataArray = data.map((logo) => {
//       return (
//          <>
//             <div className="brand__item-wrapper">
//                <div className="brand__item">
//                   <img src={logo.company_logo} alt="" height={30}/>
//                </div>
               
//             </div>
//          </>
//       );
//    });
//   return <div>{dataArray}</div>;
// };

// export default SingleBrandItem;


import React from 'react';

const SingleBrandItem = ({ data, columns = 1 }) => {
  const itemsPerColumn = Math.ceil(data.length / columns);

  const columnsArray = Array.from({ length: columns }, (_, columnIndex) => {
    const start = columnIndex * itemsPerColumn;
    const end = start + itemsPerColumn;
    const columnData = data.slice(start, end);

    return (
      <div key={columnIndex} className="brand__column">
        {columnData.map((logo, index) => (
          <div key={index} className="brand__item">
            <img
              src={logo.company_logo}
              alt={logo.title}
              height={30} // Ajusta el valor según tus necesidades
              style={{ maxWidth: '80%', height: '5vh' }} // Añade estilos en línea aquí
            />
          </div>
        ))}
      </div>
    );
  });

  return <div className="brand__columns-wrapper">{columnsArray}</div>;
};

export default SingleBrandItem;

